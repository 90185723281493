import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { FaExternalLinkAlt } from "react-icons/all"
import { graphql } from "gatsby"
import Banner from "../components/banner"
import PageContent from "../components/page-content"

const MannschaftenPage = ({ data }) => {
  const { image, title, htvLinks, pageContent } = data.sanityPage

  return (
    <Layout>
      <Seo title={title} />
      <Banner image={image}>
        <Container style={{ maxWidth: "720px" }}>
          <h1>{title}</h1>
        </Container>
      </Banner>
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "720px" }}
      >
        <section>
          <div className="d-flex flex-column flex-md-row flex-wrap justify-content-md-around">
            {htvLinks.map(link => (
              <Button
                href={link.url.href}
                target={link.url.blank ? "_blank" : "_self"}
                variant="primary"
                className="px-4 m-2 d-flex align-items-center justify-content-center"
              >
                {link.title}
                <FaExternalLinkAlt className="ml-2" />
              </Button>
            ))}
          </div>
          <p className="text-center">
            Mehr Infos auf{" "}
            <a href="https://www.hamburger-tennisverband.de">
              www.hamburger-tennisverband.de
            </a>
          </p>
        </section>
        <PageContent content={pageContent} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityPage(_id: { eq: "mannschaften" }) {
      id
      title
      _id
      image {
        alt
        asset {
          url
        }
      }
      htvLinks {
        title
        url {
          blank
          href
        }
      }
      pageContent {
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`
export default MannschaftenPage
